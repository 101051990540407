import { createContext, useContext, useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { endOfDay, formatISO, startOfDay } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { firestore, storage } from "../../firebase/init";
import { Poop } from "../../firebase/types";
import { POOPS_COLLECTION, FETCH_LIMIT } from "../../global/utils";
import { increment } from "firebase/firestore";
import { geohashForLocation } from "geofire-common";
import { CoordinatesType } from "../LocationContext";
import { useAuthService } from "./AuthContext";
import usePremiumUser from "../../global/hooks/usePremiumUser";

export interface SavePoopNewPostProps {
  title: string;
  description: string;
  image: any;
  type: string;
  coordinates: CoordinatesType;
  location: string;
  showInMap: boolean;
}

interface PoopServiceContextType {
  poops: Poop[];
  mostLikedPoops: Poop[];
  poopsForMap: Poop[];
  yourPoops: Poop[];
  fetchedMore: boolean;
  createPoop: (
    props: SavePoopNewPostProps,
    base64Image: string
  ) => Promise<void>;
  updateNumberOfComments: (value: number, poopId: string) => Promise<void>;
  updateNumberOfLikes: (value: number, poopId: string) => Promise<void>;
  fetchFiveMore: () => Promise<void>;
  fetchPoopsByGeohashPrefix: (value: string) => Promise<void>;
  getPoop: (id: string) => Promise<Poop>;
  updateAllPostedPoops: (downloadUrl: string, username: string) => void;
  getUserPoops: (uid: string) => Promise<Poop[]>;
  getInitialPoops: () => Promise<void>;
  getPoopsNotVerified: () => Promise<Poop[]>;
  changePoopType: (id: string, type: string) => Promise<void>;
  editTitle: (title: string, id: string) => Promise<void>;
  editDescription: (description: string, id: string) => Promise<void>;
  deletePoop: (id: string) => Promise<void>;
  getYourPoops: () => Promise<void>;
  getMostLikedPoops: () => Promise<void>;
  removeGpsLocation: () => void;
  getPostsToday: () => Promise<{ userIds: number; poops: number }>;
  updateAllPostedPoopsForPremium: (premium: boolean) => void;
  getAllPoopsByDates: (startDate: string, endDate: string) => Promise<Poop[]>;
  getUnverifiedPoops: () => Promise<number>;
  getPoopsToVerifyFor1: () => Promise<Poop[]>;
  getPoopsToVerifyFor2: () => Promise<Poop[]>;
  getPoopsToVerifyFor3: () => Promise<Poop[]>;
  updateVerify1: (id: string, type: string) => Promise<void>;
  updateVerify2: (id: string, type: string) => Promise<void>;
  updateVerify3: (id: string, type: string) => Promise<void>;
  getPoopsVerifiedBy1: () => Promise<number>;
  getPoopsVerifiedBy2: () => Promise<number>;
  getPoopsVerifiedBy3: () => Promise<number>;
}

const PoopServiceContext = createContext<PoopServiceContextType>({
  poops: [],
  mostLikedPoops: [],
  poopsForMap: [],
  yourPoops: [],
  fetchedMore: true,
  createPoop: async () => {},
  updateNumberOfComments: async () => {},
  updateNumberOfLikes: async () => {},
  fetchFiveMore: async () => {},
  fetchPoopsByGeohashPrefix: async () => {},
  getPoop: async () => {
    return {} as Poop;
  },
  updateAllPostedPoops: () => {},
  removeGpsLocation: () => {},
  getUserPoops: async () => {
    return [];
  },
  getInitialPoops: async () => {},
  getPoopsNotVerified: async () => [],
  changePoopType: async () => {},
  editTitle: async () => {},
  editDescription: async () => {},
  deletePoop: async () => {},
  getYourPoops: async () => {},
  getMostLikedPoops: async () => {},
  getPostsToday: async () => {
    return { userIds: 0, poops: 0 };
  },
  updateAllPostedPoopsForPremium: () => {},
  getAllPoopsByDates: async () => {
    return [];
  },
  getUnverifiedPoops: async () => 0,
  getPoopsToVerifyFor1: async () => [],
  getPoopsToVerifyFor2: async () => [],
  getPoopsToVerifyFor3: async () => [],
  updateVerify1: async () => {},
  updateVerify2: async () => {},
  updateVerify3: async () => {},
  getPoopsVerifiedBy1: async () => 0,
  getPoopsVerifiedBy2: async () => 0,
  getPoopsVerifiedBy3: async () => 0,
});

export const usePoopService = () => useContext(PoopServiceContext);

export const PoopServiceProvider = ({ children }: any) => {
  const [poops, setPoops] = useState<Poop[]>([]);
  const [mostLikedPoops, setMostLikedPoops] = useState<Poop[]>([]);
  const [poopsForMap, setPoopsForMap] = useState<Poop[]>([]);
  const [yourPoops, setYourPoops] = useState<Poop[]>([]);
  const [fetchedMore, setFetchedMore] = useState<boolean>(true);

  const lastVisible = useRef<Poop>();

  const { user } = useAuthService();
  const { isPremiumUser } = usePremiumUser();

  useEffect(() => {
    if (user?.uid) {
      getInitialPoops();
      getYourPoops();
    }
  }, [user?.uid]);

  const getPoopsVerifiedBy1 = async () => {
    const poopsRef = collection(firestore, "poops");
    const q = query(poopsRef, where("firstVerification", ">", ""));
    let querySnapshot = await getDocs(q);
    return querySnapshot.size;
  };

  const getPoopsVerifiedBy2 = async () => {
    const poopsRef = collection(firestore, "poops");
    const q = query(poopsRef, where("secondVerification", ">", ""));
    let querySnapshot = await getDocs(q);
    return querySnapshot.size;
  };

  const getPoopsVerifiedBy3 = async () => {
    const poopsRef = collection(firestore, "poops");
    const q = query(poopsRef, where("thirdVerification", ">", ""));
    let querySnapshot = await getDocs(q);
    return querySnapshot.size;
  };

  const getPoopsToVerifyFor1 = async () => {
    const poopsRef = collection(firestore, "poops");
    const q = query(
      poopsRef,
      where("firstVerification", "==", null),
      orderBy("createdDate", "desc"),
      limit(100)
    );
    let querySnapshot = await getDocs(q);
    let documentData: Poop[] = querySnapshot.docs.map(
      (document) => document.data() as Poop
    );
    return documentData;
  };

  const getPoopsToVerifyFor2 = async () => {
    const poopsRef = collection(firestore, "poops");
    const q = query(
      poopsRef,
      where("needSecondVerification", "==", true),
      orderBy("createdDate", "desc"),
      limit(100)
    );
    let querySnapshot = await getDocs(q);
    let documentData: Poop[] = querySnapshot.docs.map(
      (document) => document.data() as Poop
    );
    return documentData;
  };

  const getPoopsToVerifyFor3 = async () => {
    const poopsRef = collection(firestore, "poops");
    const q = query(
      poopsRef,
      where("needThirdVerification", "==", true),
      orderBy("createdDate", "desc"),
      limit(100)
    );
    let querySnapshot = await getDocs(q);
    let documentData: Poop[] = querySnapshot.docs.map(
      (document) => document.data() as Poop
    );
    return documentData;
  };

  const updateVerify1 = async (id: string, type: string) => {
    let docRef = doc(POOPS_COLLECTION, id);
    await updateDoc(docRef, {
      firstVerification: type,
      needSecondVerification: true,
    });
  };

  const updateVerify2 = async (id: string, type: string) => {
    let docRef = doc(POOPS_COLLECTION, id);
    await updateDoc(docRef, {
      secondVerification: type,
      needSecondVerification: false,
      needThirdVerification: true,
    });
  };

  const updateVerify3 = async (id: string, type: string) => {
    let docRef = doc(POOPS_COLLECTION, id);
    await updateDoc(docRef, {
      thirdVerification: type,
      needThirdVerification: false,
    });
  };

  const editTitle = async (title: string, id: string) => {
    let docRef = doc(POOPS_COLLECTION, id);
    await updateDoc(docRef, {
      title: title,
    });
    getYourPoops();
  };

  const editDescription = async (description: string, id: string) => {
    let docRef = doc(POOPS_COLLECTION, id);
    await updateDoc(docRef, {
      description: description,
    });
    getYourPoops();
  };

  const deletePoop = async (id: string) => {
    const date = new Date();
    const timeZone = "America/New_York"; // Specify the desired timezone
    const zonedDate = toZonedTime(date, timeZone);
    const formattedDate = formatISO(zonedDate, { representation: "complete" });
    let docRef = doc(POOPS_COLLECTION, id);
    await updateDoc(docRef, {
      deleted: true,
      modifiedDate: formattedDate,
    });
    getYourPoops();
  };

  const getMostLikedPoops = async () => {
    let initialQuery = query(
      POOPS_COLLECTION,
      orderBy("likes", "desc"),
      where("status", "==", "approved"),
      where("deleted", "==", false),
      limit(50)
    );

    let documentSnapshots = await getDocs(initialQuery);
    let documentData: Poop[] = documentSnapshots.docs.map(
      (document) => document.data() as Poop
    );
    // lastVisible.current = documentData[documentData.length - 1];
    setMostLikedPoops(documentData);
  };

  const createPoop = async (
    newPost: SavePoopNewPostProps,
    base64Image: string
  ) => {
    const id = uuidv4();
    let imageUrl = newPost?.image;
    if (newPost.image) {
      imageUrl = await uploadPoopImage(newPost?.image, newPost?.type, id);
    }
    let geohash;
    if (newPost?.coordinates) {
      geohash = geohashForLocation([
        newPost.coordinates.latitude,
        newPost.coordinates.longitude,
      ]);
    }

    const date = new Date();
    const timeZone = "America/New_York"; // Specify the desired timezone
    const zonedDate = toZonedTime(date, timeZone);
    const formattedDate = formatISO(zonedDate, { representation: "complete" });

    //@ts-ignore
    let poop: Poop = {
      ...newPost,
      id,
      geohash: geohash ?? "",
      image: imageUrl,
      likes: 0,
      comments: 0,
      status: newPost?.type?.toLowerCase() === "other" ? "pending" : "approved",
      uid: user?.uid ?? "",
      userPhotoURL: user?.photoURL ?? "",
      username: user?.username ?? "",
      createdDate: formattedDate,
      modifiedDate: formattedDate,
      isCompleted: true,
      verified: false,
      deleted: false,
      isUserPremium: isPremiumUser(user) ?? false,
      private: false,
    };
    const docRef = doc(POOPS_COLLECTION, id);
    await setDoc(docRef, poop);
    if (newPost?.type?.toLowerCase() !== "other") {
      setPoops((prevPoops) => [poop, ...prevPoops]);
      runScreeningOnPoop(base64Image, id);
    }
    setYourPoops((prevPoops) => [poop, ...prevPoops]);
  };

  const runScreeningOnPoop = async (base64Image: string, id: string) => {
    const url =
      "https://us-central1-poopmania-prod.cloudfunctions.net/requestChatGptResponseWithStructuredData";

    const payload = {
      base64Image: base64Image,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      const result = await response.json();

      const aiScreening = JSON.parse(
        result?.response.choices[0].message.function_call?.arguments
      );

      const docRef = doc(POOPS_COLLECTION, id);
      await updateDoc(docRef, {
        aiScreening: aiScreening,
      });

      setPoops((prevPoops) =>
        prevPoops.map((poop) => {
          if (poop.id === id) {
            return { ...poop, aiScreening: aiScreening };
          }
          return poop;
        })
      );
    } catch (error) {
      console.error("Error calling analyzeImage API:", error);
    }
  };

  const uploadPoopImage = async (image: any, type: string, id: string) => {
    const filePath = `poops/${user?.email}/${type}/${id}`;
    const refStorage = ref(storage, filePath);
    let uploadedImage = await uploadBytes(refStorage, image);
    const downloadUrl = await getDownloadURL(uploadedImage.ref);
    return downloadUrl;
  };

  const getInitialPoops = async () => {
    let initialQuery = query(
      POOPS_COLLECTION,
      orderBy("modifiedDate", "desc"),
      where("status", "==", "approved"),
      where("deleted", "==", false),
      where("private", "==", false),
      limit(FETCH_LIMIT)
    );

    let documentSnapshots = await getDocs(initialQuery);
    console.log(documentSnapshots.size);
    let documentData: Poop[] = documentSnapshots.docs.map(
      (document) => document.data() as Poop
    );
    lastVisible.current = documentData[documentData.length - 1];
    setPoops(documentData);
  };

  const updateNumberOfComments = async (value: number, poopId: string) => {
    const date = new Date();
    const timeZone = "America/New_York"; // Specify the desired timezone
    const zonedDate = toZonedTime(date, timeZone);
    const formattedDate = formatISO(zonedDate, { representation: "complete" });
    updateDoc(doc(POOPS_COLLECTION, poopId), {
      comments: increment(value),
      modifiedDate: formattedDate,
    });
  };

  const updateNumberOfLikes = async (value: number, poopId: string) => {
    setPoops((prevPoops) =>
      prevPoops.map((poop) => {
        if (poop.id === poopId) {
          return { ...poop, likes: poop.likes + value };
        }
        return poop;
      })
    );
    const date = new Date();
    const timeZone = "America/New_York"; // Specify the desired timezone
    const zonedDate = toZonedTime(date, timeZone);
    const formattedDate = formatISO(zonedDate, { representation: "complete" });
    updateDoc(doc(POOPS_COLLECTION, poopId), {
      likes: increment(value),
      modifiedDate: formattedDate,
    });
  };

  const fetchFiveMore = async () => {
    if (!lastVisible?.current || !fetchedMore) {
      return;
    }
    let additionalQuery = query(
      POOPS_COLLECTION,
      where("modifiedDate", "<", lastVisible.current?.modifiedDate),
      where("status", "==", "approved"),
      where("deleted", "==", false),
      where("private", "==", true),
      orderBy("modifiedDate", "desc"),
      startAfter(lastVisible.current?.modifiedDate),
      limit(FETCH_LIMIT)
    );

    let documentSnapshots = await getDocs(additionalQuery);
    let documentData: Poop[] = documentSnapshots.docs.map(
      (document) => document.data() as Poop
    );

    if (documentData.length !== 0) {
      lastVisible.current = documentData[documentData.length - 1];
      setPoops((prevPoops) => [...prevPoops, ...documentData]);
    } else {
      setFetchedMore(false); // Update the state to indicate no more items are available
    }
  };

  const getYourPoops = async () => {
    let initialQuery = query(
      POOPS_COLLECTION,
      orderBy("createdDate", "desc"),
      where("uid", "==", user?.uid),
      where("deleted", "==", false)
    );

    let documentSnapshots = await getDocs(initialQuery);
    let documentData: Poop[] = documentSnapshots.docs.map(
      (document) => document.data() as Poop
    );
    setYourPoops(documentData);
  };

  const getUserPoops = async (uid: string) => {
    let initialQuery = query(
      POOPS_COLLECTION,
      orderBy("createdDate", "desc"),
      where("uid", "==", uid),
      where("status", "==", "approved"),
      where("deleted", "==", false)
    );

    let documentSnapshots = await getDocs(initialQuery);
    let documentData: Poop[] = documentSnapshots.docs.map(
      (document) => document.data() as Poop
    );
    return documentData;
  };

  const getPoop = async (id: string) => {
    const docRef = doc(POOPS_COLLECTION, id);
    const docSnap = await getDoc(docRef);
    return docSnap.data() as Poop;
  };

  const fetchPoopsByGeohashPrefix = async (geohashPrefix: string) => {
    const q = query(
      POOPS_COLLECTION,
      where("geohash", ">=", geohashPrefix),
      where("geohash", "<=", geohashPrefix + "\uf8ff"),
      where("status", "==", "approved"),
      where("deleted", "==", false),
      where("showInMap", "==", true)
    );

    try {
      let documentSnapshots = await getDocs(q);
      let documentData: Poop[] = documentSnapshots.docs.map(
        (document) => document.data() as Poop
      );
      setPoopsForMap(documentData);
    } catch (error) {
      console.error("Error fetching poops by geohash prefix: ", error);
    }
  };

  const removeGpsLocation = async () => {
    const poopsRef = collection(firestore, "poops");
    const q = query(poopsRef, where("uid", "==", user?.uid));
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        updateDoc(doc.ref, {
          showInMap: false,
        });
      });
    });
  };

  const getUnverifiedPoops = async () => {
    const poopsRef = collection(firestore, "poops");
    const q = query(poopsRef, where("verified", "==", false));
    let querySnapshot = await getDocs(q);
    return querySnapshot.size;
  };

  const updateAllPostedPoopsForPremium = (premium: boolean) => {
    const poopsRef = collection(firestore, "poops");
    const q = query(poopsRef, where("uid", "==", user?.uid));
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        updateDoc(doc.ref, {
          isUserPremium: premium,
        });
      });
    });
  };

  const updateAllPostedPoops = (downloadUrl: string, username: string) => {
    const poopsRef = collection(firestore, "poops");
    const q = query(poopsRef, where("uid", "==", user?.uid));
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        updateDoc(doc.ref, {
          userPhotoURL: downloadUrl,
          username: username,
        });
      });
    });
  };

  const getPoopsNotVerified = async () => {
    const poopsRef = collection(firestore, "poops");
    const q = query(poopsRef, where("verified", "==", false), limit(200));
    let documentSnapshots = await getDocs(q);
    let documentData: Poop[] = documentSnapshots.docs.map(
      (document) => document.data() as Poop
    );
    return documentData;
  };

  const changePoopType = async (id: string, type: string) => {
    const docRef = doc(POOPS_COLLECTION, id);
    updateDoc(docRef, {
      type: type,
      verified: true,
      status: type.toLowerCase() === "other" ? "denied" : "approved",
    });
  };

  const getPostsToday = async () => {
    const poopsRef = collection(firestore, "poops");
    const now = new Date();
    const startOfDay = new Date(now);
    startOfDay.setHours(0, 0, 0, 0); // Set to midnight
    const endOfDay = new Date(now);
    endOfDay.setHours(23, 59, 59, 999); // Set to 23:59:59

    // Get the start and end of the day in New York time zone
    const startISO = formatToLocalISO(startOfDay);
    const endISO = formatToLocalISO(endOfDay);

    const q = query(
      poopsRef,
      where("createdDate", ">=", startISO),
      where("createdDate", "<=", endISO)
    );
    let documentSnapshots = await getDocs(q);
    let documentData: Poop[] = documentSnapshots.docs.map(
      (document) => document.data() as Poop
    );

    const userIds = new Set<string>();
    documentData.forEach((document) => {
      userIds.add(document.uid);
    });

    return { userIds: userIds.size, poops: documentData.length };
  };

  const formatToLocalISO = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    // Combine into an ISO-like format with the local timezone offset
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const getAllPoopsByDates = async (startDate: string, endDate: string) => {
    try {
      // Convert start and end dates to the desired format (e.g., "1/26/2024 00:00:00 AM" to "1/26/2024 11:59:59 PM")
      const startOfDay = new Date(startDate);
      startOfDay.setHours(0, 0, 0, 0); // Set to midnight
      const endOfDay = new Date(endDate);
      endOfDay.setHours(23, 59, 59, 999); // Set to 23:59:59

      const formatDateTime = (date: any) =>
        date.toLocaleString("en-US", {
          hour12: true,
        });

      const startISO = formatToLocalISO(startOfDay);
      const endISO = formatToLocalISO(endOfDay);

      console.log("Formatted start date for poops:", startISO);
      console.log("Formatted end date for poops:", endISO);
      // Query Firestore

      const q = query(
        POOPS_COLLECTION,
        where("createdDate", ">=", startISO),
        where("createdDate", "<=", endISO)
      );

      const querySnapshot = await getDocs(q);

      // Map results
      const poops = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log("Poops within date range:", poops);
      return poops as unknown as Poop[];
    } catch (error) {
      console.error("Error fetching users by dates:", error);
      throw error;
    }
  };

  const value = {
    poops,
    poopsForMap,
    yourPoops,
    fetchedMore,
    createPoop,
    updateNumberOfComments,
    updateNumberOfLikes,
    fetchFiveMore,
    setPoops,
    fetchPoopsByGeohashPrefix,
    getPoop,
    updateAllPostedPoops,
    getUserPoops,
    getInitialPoops,
    getPoopsNotVerified,
    changePoopType,
    mostLikedPoops,
    editTitle,
    editDescription,
    deletePoop,
    getYourPoops,
    getMostLikedPoops,
    removeGpsLocation,
    getPostsToday,
    updateAllPostedPoopsForPremium,
    getAllPoopsByDates,
    getUnverifiedPoops,
    getPoopsToVerifyFor1,
    getPoopsToVerifyFor2,
    getPoopsToVerifyFor3,
    updateVerify1,
    updateVerify2,
    updateVerify3,
    getPoopsVerifiedBy1,
    getPoopsVerifiedBy2,
    getPoopsVerifiedBy3,
  };

  return (
    <PoopServiceContext.Provider value={value}>
      {children}
    </PoopServiceContext.Provider>
  );
};
